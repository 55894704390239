import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccountData } from "../localDatabase/mainDB";
import Header from "../components/Header";
import { format } from "date-fns";

import Sidebar from "../components/Sidebar";
import { useAppSelector } from "../hooks/reduxHooks";
import { logActivity } from "../hooks/functions";
import { ProfilePicture } from "../constants/endpoints";

export default function TransactionDetails() {
  const { chosenAcc } = useAppSelector((state) => state.user);

  const location = useLocation();
  const navigate = useNavigate();
  const { booking } = location.state;

  const otherParty =
    booking.senderCustomer?.id !== chosenAcc?.accountHolder?.id
      ? booking.senderCustomer
      : booking.recipientCustomer;

  const initials = otherParty.name
    .split(" ")
    .map((n: string) => n[0])
    .join("");

  logActivity("booking", booking);
  logActivity("otherParty", otherParty);

  // const initials = useMemo(
  //   () =>
  //     otherParty.name
  //       .split(" ")
  //       .map((n: string) => n[0])
  //       .join(""),
  //   [otherParty.name]
  // );

  return (
    <div style={{ backgroundColor: "#F5F5F5", height: "100vh" }}>
      <Sidebar />
      <div style={{ marginLeft: "7rem" }}>
        <br />
        <Header title={"Detajet e transaksionit"} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "60vh",
              backgroundColor: "#FFFFFF",
              borderRadius: "15px",
              padding: "0 20px",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 100,
                  margin: "0 auto 20px auto",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#546BEA",
                }}
              >
                <img
                  src={booking.avatar}
                  alt="Profile"
                  style={{ width: "100%", height: "100%", borderRadius: 100 }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "32px",
                    fontFamily: "Poppins",
                    color: "#546BEA",
                  }}
                >
                  {otherParty.name}
                </span>

                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "40px",
                    fontFamily: "Poppins",
                  }}
                >
                  {(booking.senderCustomer?.id === chosenAcc?.accountHolder?.id
                    ? "-" + booking.amount.value
                    : "+" + booking.amount.value) +
                    " " +
                    (chosenAcc?.currency === "ALL" ? "Lekë" : "Euro")}
                </span>
              </div>
            </div>

            {/*<div*/}
            {/*  style={{*/}
            {/*    width: "80%",*/}
            {/*    // margin: "0 auto",*/}
            {/*    borderRadius: "10px",*/}

            {/*    backgroundColor: "white",*/}
            {/*    padding: "20px",*/}
            {/*    textAlign: "center",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      width: 200,*/}
            {/*      height: 200,*/}
            {/*      borderRadius: 100,*/}
            {/*      margin: "0 auto 20px auto",*/}
            {/*      textAlign: "center",*/}
            {/*      display: "flex",*/}
            {/*      justifyContent: "center",*/}
            {/*      alignItems: "center",*/}
            {/*      backgroundColor: "#546BEA",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {booking.avatar ? (*/}
            {/*      <img*/}
            {/*        src={booking.avatar}*/}
            {/*        alt="Profile"*/}
            {/*        style={{*/}
            {/*          width: "100%",*/}
            {/*          height: "100%",*/}
            {/*          borderRadius: 100,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          fontSize: "20px",*/}
            {/*          alignContent: "center",*/}
            {/*          color: "#FFF",*/}
            {/*          fontFamily: "Poppins",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {initials}*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      display: "flex",*/}
            {/*      flexDirection: "column",*/}
            {/*      alignItems: "center",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <span*/}
            {/*      style={{*/}
            {/*        fontWeight: "50",*/}
            {/*        fontSize: "20px",*/}
            {/*        fontFamily: "Poppins",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {otherParty.name}*/}
            {/*    </span>*/}
            {/*    <span*/}
            {/*      style={{*/}
            {/*        fontWeight: "400",*/}
            {/*        fontSize: "24px",*/}
            {/*        fontFamily: "Poppins",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {(booking.senderCustomer?.id ===*/}
            {/*      chosenAcc?.accountHolder?.id*/}
            {/*        ? "-" + booking.amount.value*/}
            {/*        : "+" + booking.amount.value) +*/}
            {/*        " " +*/}
            {/*        (chosenAcc?.currency === "ALL" ? "Lekë" : "Euro")}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <br />
            <div style={{ margin: "10px" }}>
              <p
                style={{
                  color: "#999",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Date:{" "}
                {format(new Date(booking.creationDate), "HH:mm dd/MM/yyyy")}
              </p>
            </div>

            {!!booking.description && (
              <div
                style={{
                  marginTop: 20,
                  width: "100%",
                  margin: "10px",
                  borderRadius: "4px",
                  backgroundColor: "rgba(84, 107, 234, 0.7)",
                  padding: "20px",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >
                  {booking.description}
                </p>
              </div>
            )}

            {booking.senderCustomer !== undefined &&
              booking.recipientCustomer !== undefined &&
              !otherParty.mobileNumber?.endsWith("B") && (
                <button
                  style={{
                    width: "100%",
                    height: "68px",
                    padding: "15px",
                    alignContent: "center",
                    backgroundColor: "#636FED",
                    color: "white",
                    borderRadius: "4px",
                    fontSize: 24,
                    border: "none",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    marginBottom: "50px",
                    fontWeight: "600",
                    marginTop: "10%",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate("/confirm-payment", {
                      state: {
                        friendInfo: {
                          ...otherParty,
                          userId: otherParty.id,
                        },
                      },
                    });
                  }}
                >
                  Dërgo para
                </button>
              )}
          </div>
        </div>
        {/* <Modal isVisible={true} setIsVisible={() => {}} title={""}>
        <div>
          <div
            style={{
              marginTop:"-20px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "17px",
              lineHeight: "36px",
              textAlign: "center",
              color: "#000000",
              marginBottom: "20px",
              padding:20
            }}
          >
            Të sigurtë që doni të vazhdoni me rimbushjen e numrit{" "}
            +355 696476526 me shumën prej 500 Lekë?{" "}
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px",marginTop:"7px" }}
          >
            <button
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // padding: "12px 145px",
                gap: "10px",
                width: "315.56px",
                height: "66px",
                left: "0px",
                top: "120px",
                background: "#FFFFFF",
                border: "1px solid #546BEA",
                borderRadius: "10px",
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '22px',
                color: '#546BEA',
                cursor: "pointer",
              }}
            >

              Jo, nuk dua të vazhdoj
            </button>
            <button
            style={{ display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: '12px 145px',
            gap: '10px',
            width: '315.56px',
            height: '66px',
            left: '326.44px',
            top: '120px',
            background: '#546BEA',
            borderRadius: '10px',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '22px',
            cursor: "pointer",
            color: 'white',
            borderColor:"transparent"}}> Po, vazhdo </button>
          </div>
        </div>
      </Modal> */}
      </div>
    </div>
  );
}
