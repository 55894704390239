import React from "react";
import Sidebar from "../components/Sidebar";
import InnerTransfer from "./InnerTransfer";
import ContactList from "../components/ContactList";

export default function TransferToFriendScreen() {
  return (
    <div style={{}}>
      <Sidebar />
      <div style={{ marginLeft: "7rem", backgroundColor: "#F5F5F5" }}>
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 60,
          }}
        >
          <p
            className="depozito"
            style={{
              color: "white",
              marginLeft: "40px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              marginTop: "20px",
            }}
          >
            Transfero
          </p>
          <p
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              fontSize: "24px",
              lineHeight: "36px",
              color: "#FFFFFF",
              marginLeft: "40px",
            }}
          >
            {" "}
            Transferoni ndërmjet llogarive.{" "}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <ContactList />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "25px",
              border: "1px #cccccc",
              margin: "20px",
              marginTop: "-30px",
              backgroundColor: "#FFF",
              padding: "35px",
            }}
          >
            <InnerTransfer />
          </div>
        </div>
      </div>
    </div>
  );
}
