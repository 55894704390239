import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import "./SettingsScreen.css";
import langText from "../lang/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faUser,
  faLock,
  faGlobe,
  faLink,
  faHeadset,
  faFileInvoice,
  faPhone,
  faEnvelope,
  faCheckCircle,
  faTimesCircle,
  faCircle,
  faSackDollar,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { logOutClearDB, setIsLoggedIn } from "../localDatabase/mainDB";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";
import i18n from "i18next";
import {
  handleCardNumber,
  hexWithOpacity,
  logActivity,
} from "../hooks/functions";
import {
  BankAccounts,
  ChangeUserPassword,
  GetAccountAccessUrl,
  GetAccountsUrl,
  GetBankName,
  TransferMoney,
  WebHookEnpoint,
} from "../constants/endpoints";
import Modal from "../components/Modal";
import Lottie from "react-lottie";
import animationData from "../assets/782-check-mark-success.json";
import useWebhook from "../hooks/useWebhook";
import { RubiserAPIKEY } from "../Network";
import Intesa from "../assets/icons/banks/intesa.svg";
import Abi from "../assets/icons/banks/abi.svg";
import Alpha from "../assets/icons/banks/alpha.svg";
import BankOfAlbania from "../assets/icons/banks/bankOfAlbania.svg";
import Credins from "../assets/icons/banks/credins.svg";
import Procredit from "../assets/icons/banks/procredit.svg";
import Raiffeisen from "../assets/icons/banks/raiffeisen.svg";
import TiranaBank from "../assets/icons/banks/tiranabank.svg";
import UBA from "../assets/icons/banks/UBA.svg";
import Union from "../assets/icons/banks/union.svg";
import BKT from "../assets/icons/banks/bkt.svg";
import OTP from "../assets/icons/banks/otp.svg";
import Fibank from "../assets/icons/banks/fibank.svg";
import { formatMoney } from "../functions";
import { FaTrash } from "react-icons/fa";
import { AccountProps } from "../redux/user-slice";

let regexHasUpperCase = /^(?=.*[A-Z])(?=\S+$).{1,}$/;
let regexHasLowerCase = /^(?=.*[a-z])(?=\S+$).{1,}$/;
let regexHasNumber = /^(?=.*[0-9])(?=\S+$).{1,}$/;
let regexHasSpecialChar = /^(?=.*[@#$%+=.,!])(?=\S+$).{1,}$/;

export default function SettingsScreen() {
  const [active, setActive] = useState("personal_info");
  const navigate = useNavigate();

  useEffect(() => {
    if (active === "log_out") {
      setIsLoggedIn(false);
      logOutClearDB();

      navigate("/login");
    }
  }, [active]);

  function MenuItem({
    title,
    icon,
    disabled,
    href,
  }: {
    title: string;
    icon: any;
    disabled?: boolean;
    href?: string;
  }) {
    return (
      <a
        className={"menu-item " + (active === title ? "active" : "")}
        style={{ opacity: disabled ? 0.5 : 1 }}
        onClick={() => (disabled || !!href ? null : setActive(title))}
        href={href}
        target="_blank"
      >
        <div className="menu-item-icon">{icon}</div>
        <div className="menu-item-title">{langText(title)}</div>
      </a>
    );
  }

  async function selectLanguage(lang: "al" | "en") {
    localStorage.setItem("lang", lang);
    await i18n.changeLanguage(lang);
    // window.location.reload();
  }

  return (
    <div>
      <Sidebar />
      <div
        style={{
          marginLeft: "7rem",
          backgroundColor: "white",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 60,
          }}
        >
          <p
            className="depozito"
            style={{
              color: "white",
              marginLeft: "40px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              marginTop: "20px",
            }}
          >
            {langText("settings")}
          </p>
          <p
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              fontSize: "24px",
              lineHeight: "36px",
              color: "#FFFFFF",
              marginLeft: "40px",
            }}
          ></p>
        </div>

        <div className="columns-container">
          <div className="column" style={{ maxWidth: 350 }}>
            <MenuItem
              title={"personal_info"}
              icon={<FontAwesomeIcon icon={faUser} />}
            />
            <MenuItem
              title={"accesses_and_roles"}
              icon={<FontAwesomeIcon icon={faUser} />}
            />
            <MenuItem
              title={"password"}
              icon={<FontAwesomeIcon icon={faLock} />}
            />
            <MenuItem
              title={"withdraw_money"}
              icon={<FontAwesomeIcon icon={faSackDollar} />}
            />
            {/*<MenuItem*/}
            {/*  title={"language"}*/}
            {/*  icon={<FontAwesomeIcon icon={faGlobe} />}*/}
            {/*/>*/}
            <MenuItem title={"inp"} icon={<FontAwesomeIcon icon={faLink} />} />
            <MenuItem
              title={"support"}
              icon={<FontAwesomeIcon icon={faHeadset} />}
            />
            <MenuItem
              title={"terms"}
              icon={<FontAwesomeIcon icon={faFileInvoice} />}
              href={"https://pago.al/termat-dhe-kushtet/"}
            />

            <MenuItem
              title={"log_out"}
              icon={<FontAwesomeIcon icon={faRightFromBracket} />}
            />
          </div>
          <div className="column">
            {active === "personal_info" ? (
              <PersonalInfoView />
            ) : active === "accesses_and_roles" ? (
              <AccessesAndRoles />
            ) : active === "support" ? (
              <SupportView />
            ) : active === "password" ? (
              <PasswordView />
            ) : active === "withdraw_money" ? (
              <CashOutView />
            ) : active === "inp" ? (
              <INPView />
            ) : active === "language" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div style={{}} onClick={() => selectLanguage("al")}>
                  {langText("albanian")}
                </div>
                <div style={{}} onClick={() => selectLanguage("en")}>
                  {langText("english")}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PersonalInfoView() {
  const { UserData } = useAppSelector((state) => state.user);
  return (
    <div>
      <div className="display-item">
        <div className="display-label">{langText("name") + ": "}</div>
        <div className="display-value">{UserData?.firstName}</div>
      </div>
      <div className="display-item">
        <div className="display-label">{langText("lastname") + ": "}</div>
        <div className="display-value">{UserData?.lastName}</div>
      </div>
      <div className="display-item">
        <div className="display-label">{langText("email") + ": "}</div>
        <div className="display-value">
          {!!UserData?.email ? UserData.email : "-"}
        </div>
      </div>
      <div className="display-item">
        <div className="display-label">{langText("phone_number") + ": "}</div>
        <div className="display-value">{UserData?.mobileNumber}</div>
      </div>
      <div className="display-item">
        <div className="display-label">{langText("address") + ": "}</div>
        <div className="display-value">
          {UserData?.address == null
            ? ""
            : (UserData?.address?.line1 ?? "") +
              ", " +
              (UserData?.address?.line2 ? UserData?.address.line2 + ", " : "") +
              (UserData?.address?.city ?? "") +
              ", " +
              (UserData?.address?.state ?? "")}
        </div>
      </div>
      <div className="display-item">
        <div className="display-label">{langText("place_of_birth") + ": "}</div>
        <div className="display-value">{UserData?.birthCity}</div>
      </div>
    </div>
  );
}

function AccessesAndRoles() {
  const { UserToken, UserData } = useAppSelector((state) => state.user);
  const ACCESS_TYPES = ["SELECT", "FULL_RIGHTS", "READ_RIGHTS", "REPORTS"];
  const [accountData, setAccountData] = useState<AccountProps[]>([]);

  const [accessTypeInput, setAccessTypeInput] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");

  const handleCustomerMobileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerMobile(event.target.value);
  };

  function getAccounts() {
    fetch(GetAccountsUrl(UserData?.id), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAccountData(data);
      });
  }

  useEffect(() => {
    getAccounts();
  }, []);

  const updateAccountAccess = (
    account: any,
    accessType: string,
    actionType: string,
    customerMobile: string
  ) => {
    fetch(GetAccountAccessUrl(UserData?.id, account.id.toString()), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        account: account.id,
        mobile: customerMobile, //'+355456789',
        accessRightType: accessType,
        action: actionType,
      }),
    })
      .then((response) => {
        if (response.ok) getAccounts();
        return response.json();
      })
      .then((data) => {
        console.log("DATA", data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div>
      {accountData &&
        accountData.map((account) => {
          return (
            <div key={account.id} className="account">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "1rem",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 100,
                    }}
                  >
                    Account ID: {account.id}
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 100,
                    }}
                  >
                    Account Holder: {account.accountHolder.name}
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 100,
                    }}
                  >
                    Currency: {account.currency}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      padding: "3px",
                    }}
                  >
                    {langText("add_access")}
                  </div>
                  <input
                    type="text"
                    value={customerMobile}
                    onChange={(e) => handleCustomerMobileChange(e)}
                    placeholder="+355** *** ****"
                    className="input"
                  />{" "}
                  <select
                    className="access-type"
                    placeholder={"SELECT"}
                    value={accessTypeInput}
                    onChange={(e) => {
                      setAccessTypeInput(e.target.value);
                    }}
                  >
                    {ACCESS_TYPES.map((accessType) => {
                      return (
                        <option key={accessType} value={accessType}>
                          {accessType}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    style={{
                      backgroundColor: "#546BEA",
                      border: "none",
                      color: "white",
                      borderRadius: 8,
                      padding: "5px 36px",
                      lineHeight: "24px",
                      width: "100%",
                      marginTop: "2em",
                    }}
                    onClick={() => {
                      updateAccountAccess(
                        account,
                        accessTypeInput,
                        "add",
                        customerMobile
                      );
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              {!!account.accountAccesss &&
                account.accountAccesss.length > 0 && (
                  <div>
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 100,
                        borderBottom: "1px solid #EDEDED",
                        padding: "5px",
                      }}
                    >
                      {langText("clients_with_access")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Access Type</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {account.accountAccesss?.map((element) => {
                            console.log(element);
                            return (
                              <tr key={element.id}>
                                <td>{element.customerMobile}</td>
                                <td>{element.accessRightType}</td>
                                <td>
                                  {
                                    <FaTrash
                                      onClick={() => {
                                        updateAccountAccess(
                                          account,
                                          element.accessRightType,
                                          "remove",
                                          element.customerMobile
                                        );
                                      }}
                                    />
                                  }
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>
          );
        })}
    </div>
  );
}

function PasswordView() {
  const { UserToken, PersonId } = useAppSelector((state) => state.user);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inputsError, setInputsError] = useState("");
  const navigate = useNavigate();

  function checkPassword() {
    if (currentPassword === "" || newPassword === "" || newPassword2 === "") {
      setInputsError(langText("change_pass_empty_fields_error"));
      return;
    }
    if (newPassword !== newPassword2) {
      setInputsError(langText("change_pass_pass_not_match"));
      return;
    }
    changePassword();
  }

  async function changePassword() {
    logActivity("Bearer " + UserToken);
    logActivity(ChangeUserPassword(PersonId!));
    setLoading(true);

    const response = await fetch(ChangeUserPassword(PersonId!), {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        id: PersonId,
        password: currentPassword,
        newPassword: newPassword,
        newPassword2: newPassword2,
      }),
    });

    logActivity("ChangeUserPassword", response.status);
    if (response.status === 202) {
      setLoading(false);
      setSuccess(true);
    } else {
      setInputsError(langText("old_password_error"));
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        className="display-label"
        style={{
          fontSize: 24,
          fontWeight: 600,
          marginBottom: 20,
        }}
      >
        {langText("change_password")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div className="display-label" style={{ minWidth: 200 }}>
            {langText("current_password")}
          </div>
          <input
            className="input"
            type="password"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="display-label"
            style={{ minWidth: 200, marginBottom: 20 }}
          >
            {langText("new_password")}
          </div>
          <input
            className="input"
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="display-label"
            style={{ minWidth: 200, marginBottom: 20 }}
          >
            {langText("new_password_again")}
          </div>
          <input
            className="input"
            type="password"
            onChange={(e) => setNewPassword2(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {newPassword.length >= 8 ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color:
                newPassword.length >= 8
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_8chars")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasUpperCase.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasUpperCase.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_capital_char")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasLowerCase.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasLowerCase.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_lowercase_char")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasNumber.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasNumber.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_number")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasSpecialChar.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasSpecialChar.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_special_symbol")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!!newPassword2 && newPassword !== newPassword2 ? (
            <FontAwesomeIcon
              icon={faTimesCircle}
              color={hexWithOpacity("#FF0000", 0.7)}
            />
          ) : !!newPassword2 && newPassword === newPassword2 ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color:
                !!newPassword2 && newPassword !== newPassword2
                  ? hexWithOpacity("#FF0000", 0.7)
                  : !!newPassword2 && newPassword === newPassword2
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("old_password_not_equal_to_new")}
          </div>
        </div>
      </div>

      <div
        style={{
          alignSelf: "center",
          marginBottom: 10,
          color: hexWithOpacity("#FF0000", 0.7),
        }}
      >
        {inputsError}
      </div>
      <div
        onClick={checkPassword}
        style={{
          display: "flex",
          minWidth: 200,
          flexDirection: "column",
          backgroundColor: "#546BEA",
          color: "#FFF",
          borderRadius: 10,
          alignItems: "center",
          padding: "20px 70px",
          alignSelf: "center",
        }}
      >
        <div className="display-label" style={{}}>
          {langText("change_password")}
        </div>
      </div>
      <Modal
        isVisible={success}
        setIsVisible={setSuccess}
        title={langText("change_password_success")}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={400}
              width={400}
            />

            <button
              style={{
                marginTop: 10,
                width: "100%",
                padding: "10px",
                backgroundColor: "#546BEA",
                color: "white",
                borderRadius: "10px",
                fontSize: 18,
                border: "none",
                fontFamily: "Poppins",
              }}
              onClick={() => {
                setSuccess(false);
                setCurrentPassword("");
                setNewPassword("");
                setNewPassword2("");

                navigate("/");
              }}
              disabled={loading}
            >
              {langText("continue")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function INPView() {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [token, setToken] = useState("");
  const { deleteWebhook, saveWebhook, loading, saved, error } = useWebhook();

  useEffect(() => {
    if (!saved) return;
    setWebhookUrl(saved.url);
  }, [saved]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div className="display-label" style={{ minWidth: 200 }}>
          {langText("URL")}
        </div>
        <input
          className="input"
          type="text"
          onChange={(e) => setWebhookUrl(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div className="display-label" style={{ minWidth: 200 }}>
          {langText("secure_token")}
        </div>
        <input
          className="input"
          type="text"
          onChange={(e) => setToken(e.target.value)}
        />
      </div>

      <div
        style={{
          alignSelf: "center",
          marginBottom: 10,
          color: hexWithOpacity("#FF0000", 0.7),
        }}
      >
        {error}
      </div>
      <div
        onClick={
          !!saved ? () => deleteWebhook() : () => saveWebhook(webhookUrl, token)
        }
        style={{
          display: "flex",
          minWidth: 200,
          flexDirection: "column",
          backgroundColor: "#546BEA",
          color: "#FFF",
          borderRadius: 10,
          alignItems: "center",
          padding: "20px 70px",
          alignSelf: "center",
        }}
      >
        <div className="display-label">
          {langText(!!saved ? "delete" : "save")}
        </div>
      </div>
    </div>
  );
}

function CashOutView() {
  const { chosenAcc, PersonId, UserToken } = useAppSelector(
    (state) => state.user
  );
  const [cardHolderName, setCardHolderName] = useState("");
  const [iban, setIban] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [transferAmount, setTransferAmount] = useState("");
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getBankAccountsList();
  }, []);

  useEffect(() => {
    if (!success) return;
    getBankAccountsList();
  }, [success]);

  async function getBankAccountsList() {
    logActivity("getting ", BankAccounts(PersonId));
    fetch(BankAccounts(PersonId), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    }).then((res) => {
      logActivity("Get Bank Accounts ", res.status);
      if (res.status === 200)
        res.json().then((res) => {
          logActivity(res);
          setAccounts(res);
        });
    });
  }

  async function addBankAccounts() {
    const currency = "ALL";
    let bankName = await getBankName(iban);
    if (!bankName) {
      return;
    }

    if (cardHolderName === "" || iban === "") {
      setError(langText("fill_details"));
      setLoading(false);
      return;
    }

    setLoading(true);
    let body = JSON.stringify({
      customer: PersonId,
      iban: iban.replace(/ /g, ""),
      accountHolder: cardHolderName,
      bankName: bankName,
      // swift: swift,
      currencyCode: currency,
      account: chosenAcc?.id,
    });
    logActivity("sending : ", body);
    fetch(BankAccounts(), {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: body,
    })
      .then((res) => {
        setLoading(false);
        logActivity("Add Bank Account " + res.status);
        res.json().then((data) => {
          logActivity("Add Bank Account data ", data);
        });
        if (res.status === 200 || res.status === 201) {
          setSuccess(true);
          setIban("");
          setCardHolderName("");
        } else {
          setError(langText("error"));
        }
      })
      .catch((e) => {
        let err = Error(e);
        if (err.message === "TypeError: Network request failed") {
          setLoading(false);
        }
      });
  }

  async function getBankName(iban: string) {
    setLoading(true);

    if (!iban.startsWith("AL")) {
      setError(langText("no_albanian_bank_found"));
      setLoading(false);
      return;
    }
    logActivity("getBankName", GetBankName(iban.replace(/ /g, "")));
    let res = await fetch(GetBankName(iban.replace(/ /g, "")), {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: RubiserAPIKEY,
      },
    });
    setLoading(false);
    logActivity("getBankName " + res.status);
    if (res.status === 200) {
      let data = await res.json();
      logActivity("data", data);
      return data.bankName;
    } else if (res.status === 422 || res.status === 404) {
      setError(langText("invalid_iban"));
    } else {
      setError(langText("error_iban"));
    }
  }

  function makeTransfer() {
    setLoading(true);
    let reqBody = JSON.stringify({
      fundingSource: selectedAccount?.id,
      account: chosenAcc?.id,
      transferType: "WIRE",
      currencyCode: chosenAcc?.currency,
      amount: Number(transferAmount.replace(/,/g, "")),
      transferStatus: "PENDING",
      transferDirection: "OUTGOING",
    });
    logActivity(reqBody);
    fetch(TransferMoney(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: reqBody,
    })
      .then((res) => {
        setLoading(false);
        logActivity("makeTransfer", res.status);
        if (res.status === 201) {
          setSuccess(true);
        } else {
          setError(langText("error"));
        }
        res.json().then((resData) => {
          logActivity("makeTransfer", resData);
        });
      })
      .catch((e) => {
        setLoading(false);
        let err = Error(e);
      });
  }

  const handleChange = (event: any) => {
    logActivity(event.target.value);
    const text = (event.target.value === "" ? "0" : event.target.value).replace(
      /,/g,
      ""
    );
    if (isNaN(text) && !(text.endsWith(".") && text.split(".").length === 2))
      return;
    setTransferAmount(text);
  };

  if (!!selectedAccount)
    return (
      <div>
        <div>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => setSelectedAccount(null)}
          />
        </div>
        <div
          className="display-label"
          style={{ fontWeight: 600, marginBottom: 20 }}
        >
          {langText("select_withdraw_amount_desc") +
            (selectedAccount.currencyCode === "ALL" ? "200.00 Lekë" : "2.00 €")}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div className="display-label" style={{ minWidth: 200 }}>
            {langText("amount")}
          </div>
          <input
            className="input"
            type="number"
            value={transferAmount}
            max={15}
            onChange={handleChange}
          />
        </div>

        <div
          style={{
            alignSelf: "center",
            marginBottom: 10,
            color: hexWithOpacity("#FF0000", 0.7),
          }}
        >
          {error}
        </div>
        <div
          onClick={
            Number(transferAmount.replace(/,/g, "")) < 200
              ? undefined
              : () => setConfirm(true)
          }
          style={{
            display: "flex",
            minWidth: 200,
            flexDirection: "column",
            backgroundColor: "#546BEA",
            color: "#FFF",
            borderRadius: 10,
            alignItems: "center",
            padding: "20px 70px",
            alignSelf: "center",
            opacity: Number(transferAmount.replace(/,/g, "")) < 200 ? 0.5 : 1,
          }}
        >
          <div className="display-label">{langText("continue")}</div>
        </div>

        <Modal
          isVisible={confirm}
          setIsVisible={setConfirm}
          title={langText("bank_transfer")}
        >
          <div>
            <div>{langText("bank_transfer_confirm")}</div>
            <div>
              {formatMoney(transferAmount.replace(/,/g, "")) +
                " (- " +
                (selectedAccount.currencyCode === "ALL" ? 200 : 2).toFixed(2) +
                " " +
                langText(chosenAcc?.currency) +
                " " +
                langText("commission") +
                ")"}
            </div>

            <div
              onClick={makeTransfer}
              style={{
                display: "flex",
                minWidth: 200,
                flexDirection: "column",
                backgroundColor: "#546BEA",
                color: "#FFF",
                borderRadius: 10,
                alignItems: "center",
                padding: "20px 70px",
                alignSelf: "center",
                marginTop: 20,
              }}
            >
              <div className="display-label">{langText("continue")}</div>
            </div>
          </div>
        </Modal>

        <Modal
          isVisible={success}
          setIsVisible={setSuccess}
          title={langText("tranfer_to_bank")}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={400}
                width={400}
              />

              <button
                style={{
                  marginTop: 10,
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "#546BEA",
                  color: "white",
                  borderRadius: "10px",
                  fontSize: 18,
                  border: "none",
                  fontFamily: "Poppins",
                }}
                onClick={() => {
                  setSuccess(false);
                  navigate("/");
                }}
                disabled={loading}
              >
                {langText("continue")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div className="display-label" style={{ minWidth: 200 }}>
          {langText("account_holder_name")}
        </div>
        <input
          className="input"
          type="text"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div className="display-label" style={{ minWidth: 200 }}>
          {langText("iban")}
        </div>
        <input
          className="input"
          type="text"
          value={iban}
          onChange={(e) => {
            if (e.target.value.length <= iban.length) setIban(e.target.value);
            else setIban(handleCardNumber(e.target.value));
          }}
          maxLength={43}
        />
      </div>

      <div
        style={{
          alignSelf: "center",
          marginBottom: 10,
          color: hexWithOpacity("#FF0000", 0.7),
        }}
      >
        {error}
      </div>
      <div
        onClick={addBankAccounts}
        style={{
          display: "flex",
          minWidth: 200,
          flexDirection: "column",
          backgroundColor: "#546BEA",
          color: "#FFF",
          borderRadius: 10,
          alignItems: "center",
          padding: "20px 70px",
          alignSelf: "center",
        }}
      >
        <div className="display-label">{langText("add")}</div>
      </div>

      <div>
        {accounts.map((account) => {
          return (
            <div
              onClick={() => setSelectedAccount(account)}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                borderRadius: 10,
                boxShadow: "0 4px 8px 0 rgba(84, 107, 234, 0.2)",
                margin: 10,
              }}
            >
              <div>{getIcon(account.bankName)}</div>
              <div>{account.bankName}</div>
              <div>
                {langText(
                  account.currencyCode === "EUR"
                    ? "EUR_long"
                    : account.currencyCode
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const getIcon = (iconName: string) => {
  if (iconName.includes("FIBANK"))
    return <img src={Fibank} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("TIRANA"))
    return <img src={TiranaBank} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("BKT"))
    return <img src={BKT} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("OTP"))
    return <img src={OTP} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("CREDINS"))
    return <img src={Credins} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("RAIFFEISEN"))
    return <img src={Raiffeisen} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("INTESA"))
    return <img src={Intesa} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("ALPHA"))
    return <img src={Alpha} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("UNION"))
    return <img src={Union} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("UNITED"))
    return <img src={UBA} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("PROCREDIT"))
    return <img src={Procredit} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("AMERICAN"))
    return <img src={Abi} style={{ width: 100, height: 100 }} />;
  if (iconName.includes("BANK OF ALBANIA"))
    return <img src={BankOfAlbania} style={{ width: 100, height: 100 }} />;
};

function SupportView() {
  return (
    <div>
      <div className="display-label">{langText("support_description")}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          margin: "20px 0",
        }}
      >
        <a
          style={{
            display: "flex",
            minWidth: 200,
            flexDirection: "column",
            backgroundColor: "#546BEA",
            color: "#FFF",
            borderRadius: 10,
            alignItems: "center",
            padding: 30,
          }}
          href={"tel:+355696660031"}
          target="_blank"
        >
          <FontAwesomeIcon icon={faPhone} size={"2xl"} />
          <div className="display-label" style={{ marginTop: 20 }}>
            {langText("support_call")}
          </div>
        </a>
        <a
          style={{
            display: "flex",
            minWidth: 200,
            flexDirection: "column",
            backgroundColor: "#546BEA",
            color: "#FFF",
            borderRadius: 10,
            alignItems: "center",
            padding: 30,
          }}
          href={"mailto:info@rbcn.al"}
          target="_blank"
        >
          <FontAwesomeIcon icon={faEnvelope} size={"2xl"} />
          <div className="display-label" style={{ marginTop: 20 }}>
            {langText("email")}
          </div>
        </a>
      </div>
      <div
        style={{
          backgroundColor: "#F5F5F5",
          padding: 20,
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon icon={faClock} size={"2xl"} color={"#546BEA"} />

        <div style={{ margin: "20px 0 0 30px" }}>
          <div className="display-item">
            <div className="display-label">
              {langText("support_working_Mon_Fri")}
            </div>
            <div className="display-value">
              {langText("support_working_hours_Mon_Fri")}
            </div>
          </div>
          <div className="display-item">
            <div className="display-label">
              {langText("support_working_Sut")}
            </div>
            <div className="display-value">
              {langText("support_working_hours_Sut")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
