import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

type Props = {
  link: string;
  show: boolean;
  setShow: (show: boolean) => void;
};
export default function LinkWrapper({ link, show, setShow }: Props) {
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <iframe
            onLoad={(e) => {
              console.log("onLoad", e);
            }}
            src={link}
            style={{ width: "100%", height: "400px" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
