import React from "react";
import Sidebar from "../components/Sidebar";
import pagocard from "../assets/icons/pagocard.png";
import langText from "../lang/i18n";

export default function PagoCardScreen() {
  return (
    <div>
      <Sidebar />
      <div
        style={{
          background:
            "linear-gradient(179.41deg, #546BEA 0.51%, #5BC6FF 132.96%)",
          backgroundRepeat: "repeat",
          marginLeft: "7rem",
        }}
      >
        <h1
          style={{
            color: "white",
            marginLeft: "40px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "40px",
            lineHeight: "60px",
          }}
        >
          Karta Pago
        </h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginLeft: "60px" }}>
            <img src={pagocard} alt="User" style={{ marginTop: "90px" }} />
          </span>

          <span
            style={{
              color: "#FFF",
              fontSize: 56,
              fontFamily: "Poppins",
              fontWeight: 700,
            }}
          >
            {langText("coming_soon")}
          </span>
        </div>
      </div>
    </div>
  );
}
