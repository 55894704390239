import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEV_BACKEND, GetUserInfo, LoginUrl } from "../constants/endpoints";
import { getIsLoggedIn } from "../localDatabase/mainDB";
import "./LoginScreen.css";
import logo from "../assets/Pago-Logo.png";
import { useAppDispatch } from "../hooks/reduxHooks";
import { setUserData, setUserToken } from "../redux/user-slice";
import { logActivity } from "../hooks/functions";
import langText from "../lang/i18n";
import { useModal } from "../components/modals/ModalService";
import use2FAuth from "../hooks/use2FAuth";

const LoginScreen = () => {
  const dispatch = useAppDispatch();
  const [mobileNumber, setMobileNumber] = useState("+355");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { showAuthModal } = use2FAuth();

  useEffect(() => {
    // check if the user is already logged in
    const isLoggedIn = getIsLoggedIn();
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMobileNumber(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    showAuthModal(
      () => successAuth(),
      () => failAuth(),
      mobileNumber
    );
    return;
  };

  async function successAuth() {
    setLoading(true);
    console.log("Login attempt");
    logActivity("Mobile Number:", mobileNumber);
    logActivity("Password:", password);
    logActivity(LoginUrl());

    let username = mobileNumber;

    if (username.startsWith("355")) {
      username = "+" + username;
    } else if (username.startsWith("06")) {
      username = "+355" + username.substring(1);
    }

    const headers = {
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Content-Type": "application/json",
      "Web-Client": "Q29taW5nRnJvbVdlYg==",
    };
    logActivity("headers:", headers);

    const response = await fetch(LoginUrl(), {
      method: "POST",
      headers: headers,
      credentials: "same-origin",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    logActivity("Login status: " + response.status);
    setLoading(false);
    if (response.status !== 200) {
      setErrorMessage("Invalid credentials");
      return;
    }
    const data = await response.json();
    logActivity(data);
    const { access_token, id } = data;
    if (!!access_token) {
      dispatch(setUserToken(access_token));

      const userData = await fetch(GetUserInfo(id), {
        method: "GET",
        //mode: 'no-cors',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      }).then((response) => {
        return response.json();
      });

      dispatch(setUserData(userData));
      navigate("/");
    }
  }

  async function failAuth() {
    console.log("Failed auth");
    setErrorMessage(langText("not_identified"));
  }

  return (
    <div className="login-screen">
      <div className="form-container">
        {LoginUrl().startsWith(DEV_BACKEND) && <h1>DEV</h1>}
        <img
          src={logo}
          style={{ maxWidth: "40%", height: "auto", marginBottom: "50px" }}
        />
        <form onSubmit={handleLogin}>
          <div>
            <label htmlFor="mobileNumber">{langText("phone_number")}:</label>
            <input
              type="tel"
              id="mobileNumber"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
          </div>
          <div>
            <label htmlFor="password">{langText("password")}:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <p id="error-message">{errorMessage}</p>
          <button type="submit">{langText("lg")}</button>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
