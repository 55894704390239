

export type UtilityProps = {
  id: number;
  category_id: number;
  short_name: string;
  long_name: string;
  info_desc?: string;
  nuis: string;
  address: string | null;
  operatorId?: string;
  logo: any;
  searchNames: string;
  services: ServiceProps[];
};

export type ServiceProps = {
  id: number;
  name: string;
  desc?: string;
  type: "topUp" | "invoice" | "search" | "scan";
  info_desc?: string;
  currency: "ALL" | "EUR";
  operatorId?: string;
  logo?: any;
  minValue?: number;
  maxValue?: number;
  maxContractLength?: number;
  fields?: FieldProps[];
};

export type FieldProps = {
  label: string;
  type: "text" | "select" | "scan";
  required: boolean;
  pattern?: string;
  keyboardType?: "phone-pad" | "numbers-and-punctuation";
  autoCapitalize?: "characters";
  placeholder?: string;
};

const ukFields: FieldProps[] = [
  {
    label: "bill_id",
    type: "text",
    required: true,
    keyboardType: "phone-pad",
  },
];

const smartCardFields: FieldProps[] = [
  {
    label: "Smart Card",
    type: "text",
    required: true,
    pattern: "###########-#",
   
  },
];

const Utilities: UtilityProps[] = [
  {
    id: 11,
    category_id: 1,
    short_name: "Digicom",
    long_name: "Digicom the real fiber",
    nuis: "X1234567Y",
    address: null,
    logo: require("../assets/images/Utilities/digicom.png"),
    searchNames: "digicom , internet , fiber,  ",
    operatorId: "25",
    services: [
      {
        id: 1,
        name: "internet_bills",
        desc: "pay_digicom",
        type: "invoice",
        info_desc: "info_pay_digicom",
        maxContractLength: 5,
        currency: "ALL",
        fields: [
          {
            label: "digicom_customer_no",
            type: "text",
            required: true,
            pattern: "DG-##-####-R",
            autoCapitalize: "characters",
          },
        ],
      },
    ],
  },

  {
    id: 1,
    category_id: 1,
    short_name: "Vodafone",
    long_name: "Vodafone power to you",
    info_desc: "info_pay_vodafone",
    nuis: "X1234567Y",
    address: null,
    operatorId: "1",
    logo: require("../assets/images/Utilities/Vodafone.png"),
    searchNames:
      "Rimbushje Celulari, Telefon, Rimbushje Online, Paguaj Fatura Vodafone, Numer me kontrate, Paketa interneti, Paketa mujore, Paketa javore, Paketa Roaming",
    services: [
      {
        id: 1,
        name: "mobile_refill",
        desc: "mobile_refill_desc",
        info_desc: "info_mobile_refill_vod",
        type: "topUp",
        currency: "ALL",
        minValue: 300,
        maxValue: 3200,
      },
      {
        id: 2,
        name: "invoice",
        desc: "pay_invoice_vodafone",
        info_desc: "info_pay_invoice_vod",
        type: "invoice",
        currency: "ALL",
        maxContractLength: 5,
        fields: [
          {
            label: "customerName",
            type: "text",
            required: true,
            placeholder: "your_name",
          },
          {
            label: "accountNo_phone_number",
            type: "text",
            required: true,
  
          },
        ],
      },
      {
        id: 3,
        name: "bundle",
        desc: "activate_bundle",
        info_desc: "info_bundle_vod",
        type: "search",
        currency: "ALL",
        maxContractLength: 5,
        fields: [
          {
            label: "phone_number",
            type: "text",
            required: true,
            pattern: "+355#########",
            keyboardType: "phone-pad",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    category_id: 1,
    short_name: "One",
    long_name: "One Telecomunications",
    info_desc: "info_pay_one",
    nuis: "X1234567Y",
    address: null,
    operatorId: "15",
    logo: require("../assets/images/Utilities/One.jpg"),
    searchNames:
      "Rimbushje Celulari, Telefon, Rimbushje Online, Paguaj Fatura One, Numer me kontrate, Paketa interneti, Paketa mujore, Paketa javore, Paketa Roaming",
    services: [
      {
        id: 1,
        name: "mobile_refill",
        desc: "mobile_refill_desc",
        info_desc: "info_mobile_one",
        type: "topUp",
        currency: "ALL",
        minValue: 200,
        maxValue: 3200,
      },
    ],
  },
  // {
  //   id: 3,
  //   category_id: 1,
  //   nuis: "X1234567Y",
  //   short_name: "Albtelecom",
  //   long_name: "Albtelecom",
  //   info_desc: "info_pay_albtelecom",
  //   address: null,
  //   operatorId: "16",
  //   logo: require("../assets/images/Utilities/Albtelecom.png"),
  //   searchNames:
  //     "Rimbushje Celulari, Telefon, Rimbushje Online, Paguaj Celularin, eagle, ",
  //   services: [
  //     {
  //       id: 1,
  //       name: "mobile_refill",
  //       desc: "mobile_refill_desc",
  //       info_desc: "info_mobile_albtelecom",
  //       type: "topUp",
  //       currency: "ALL",
  //       minValue: 100,
  //       maxValue: 3200,
  //     },
  //   ],
  // },
  {
    id: 4,
    category_id: 1,
    nuis: "X1234567Y",
    short_name: "Digitalb",
    long_name: "Digitalb",
    address: null,
    operatorId: "18",
    logo: require("../assets/images/Utilities/Digitalb.png"),
    searchNames:
      "Digitalb, Digitalb Oferta, Paketa satalitore, Paketa tokesore, Paketa tokësore, Paketa toksore, SAT, TOK, Flybox, Paketa futboll, Superliga, Paketa Premium, Paketa Select, Popcorn, Paketa Zapper",
    services: [
      {
        id: 1,
        name: "abonim",
        desc: "abonim pakete",
        info_desc: "info_pay_digitalb",
        type: "search",
        currency: "ALL",
        maxContractLength: 5,
        fields: smartCardFields,
      },
    ],
  },
  {
    id: 5,
    category_id: 1,
    nuis: "X1234567Y",
    short_name: "Tring Tv",
    long_name: "Tring Tv",
    operatorId: "19",
    logo: require("../assets/images/Utilities/Tring.png"),
    searchNames:
      "Tring Tv, Oferta Tring, Oferta TOK, Oferta Start, Tring TV Tokesore, Tring Tokesore, Platforma Tring Tokesore, Toksore",
    address: null,
    services: [
      {
        id: 1,
        name: "abonim",
        desc: "abonim pakete",
        info_desc: "info_pay_tring",
        type: "search",
        currency: "ALL",
        maxContractLength: 5,
        fields: smartCardFields,
      },
    ],
  },
  // {
  //   id: 6,
  //   category_id: 1,
  //   short_name: "Host.al",
  //   long_name: "Host.al",
  //   nuis: "X1234567Y",
  //   address: null,
  //   logo: require("../assets/images/Utilities/Host.al.png"),
  //   searchNames: "Host.al, Host al, Host, Domain, Server",
  //   services: [
  //     {
  //       id: 2,
  //       name: "invoice",
  //       desc: "pay_invoice",
  //       currency: "ALL",
  //       fields: [
  //         {
  //           label: "invoice",
  //           type: "text",
  //           required: true,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   category_id: 1,
  //   short_name: "devPOS",
  //   long_name: "devPOS",
  //   info_desc: "info_pay_dev",
  //   nuis: "X1234567Y",
  //   address: null,
  //   operatorId: "none",
  //   logo: require("../assets/images/Utilities/DevPos.png"),
  //   searchNames: "devPOS, Fizkalizim, Fature Fiskale, Dev Pos",
  //   services: [
  //     {
  //       id: 1,
  //       name: "scan_qr",
  //       type: "scan",
  //       currency: "ALL",
  //       minValue: 300,
  //       maxValue: 3200,
  //     },
  //   ],
  // },
  {
    id: 8,
    category_id: 1,
    short_name: "water_company",
    long_name: "Ujësjellës Kanalizime",
    info_desc: "info_pay_uk",
    nuis: "X1234567Y",
    address: null,
    logo: require("../assets/images/Utilities/uk.png"),
    searchNames:
      "Ujësjellës Kanalizime, Ujesjelles Kanalizime, Fatura Uji, " +
      "Durres, Durresi,  Ujësjellës Kanalizime Durrës, Ujesjellës Kanalizime Durres, UKD, UK Durrës, UK Durres, " +
      "Vlore, Vlorë, Vlora, Ujësjellës Kanalizime Vlore, Ujësjellës Kanalizime Vlorë, UKV, UK Vlorë, UK Vlore, " +
      "Elbasan, Ujësjellës Kanalizime Elbasan, UKE, UK Elbasan" +
      "Tirane, Tiranë, Tirana, Ujësjellës Kanalizime Tirane, UKT, UK Tirane",
    services: [
      {
        id: 1,
        name: "UK Tiranë",
        desc: "pay_invoice_ukt",
        type: "invoice",
        logo: require("../assets/images/Utilities/ukt.png"),
        operatorId: "22",
        currency: "ALL",
        maxContractLength: 5,
        fields: [
          {
            label: "bill_id",
            type: "text",
            required: true,
          },
        ],
      },
      {
        id: 2,
        name: "UK Durrës",
        desc: "pay_invoice_ukd",
        info_desc: "info_pay_invoice_uk",
        type: "invoice",
        logo: require("../assets/images/Utilities/ukd.png"),
        operatorId: "20",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },

      {
        id: 10,
        name: "UK Kavajë",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/ukd.png"),
        type: "invoice",
        operatorId: "33",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },

      {
        id: 11,
        name: "UK Krujë",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/ukd.png"),
        type: "invoice",
        operatorId: "34",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },

      {
        id: 12,
        name: "UK Rrogozhinë",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/ukd.png"),
        type: "invoice",
        operatorId: "35",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },
      {
        id: 3,
        name: "UK Vlorë",
        desc: "pay_invoice_ukv",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/ukv.png"),
        type: "invoice",
        operatorId: "21",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },
      {
        id: 4,
        name: "UK Elbasan",
        desc: "pay_invoice_uke",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/uke.jpg"),
        type: "invoice",
        operatorId: "27",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },
      {
        id: 5,
        name: "UK Librazhd",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/uke.jpg"),
        type: "invoice",
        operatorId: "28",
        currency: "ALL",
        maxContractLength: 5,
        fields: [
          {
            label: "bill_id",
            type: "text",
            required: true,
          
          },
        ],
      },
      {
        id: 6,
        name: "UK Peqin",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/uke.jpg"),
        type: "invoice",
        operatorId: "29",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },
      {
        id: 7,
        name: "UK Belsh",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/uke.jpg"),
        type: "invoice",
        operatorId: "30",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },

      {
        id: 8,
        name: "UK Gramsh",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/uke.jpg"),
        type: "invoice",
        operatorId: "31",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },
      {
        id: 9,
        name: "UK Cerrik",
        desc: "pay_invoice_uk",
        info_desc: "info_pay_invoice_uk",
        logo: require("../assets/images/Utilities/uke.jpg"),
        type: "invoice",
        operatorId: "32",
        currency: "ALL",
        maxContractLength: 5,
        fields: ukFields,
      },
    ],
  },
  {
    id: 9,
    category_id: 1,
    short_name: "police",
    long_name: "Policia e shtetit",
    nuis: "X1234567Y",
    address: null,
    logo: require("../assets/images/Utilities/policia.png"),
    searchNames:
      "Policia, Policia shtetit, policia e shtetit, gjobat, makina, makine, makinë, koalidim, ",
    operatorId: "23",
    services: [
      {
        id: 1,
        name: "fines",
        desc: "pay_fines",
        type: "invoice",
        info_desc: "info_pay_police",
        currency: "ALL",
        maxContractLength: 10,
        fields: [
          {
            label: "license_plate",
            type: "text",
            required: true,
            // placeholder: ("license_plate"),
            autoCapitalize: "characters",
          },
        ],
      },
    ],
  },
  // {
  //   id: 10,
  //   category_id: 1,
  //   short_name: "Taksa Vendore",
  //   long_name: "Taksa Vendore",
  //   info_desc: ("info_pay_taksa"),
  //   nuis: "X1234567Y",
  //   address: null,
  //   logo: require("../assets/images/Utilities/policia.png"),
  //   searchNames: "Taksa Vendore , taksat, patos ",
  //   services: [
  //     {
  //       id: 1,
  //       name: "Taksa Vendore Patos",
  //       desc: "Paguaj taksat vendore te Patosit",
  //       type: "invoice",
  //       operatorId: "26",
  //       currency: "ALL",
  //       fields: [
  //         {
  //           label: "nid",
  //           type: "text",
  //           required: true,
  //           placeholder: "AXXXXXXXXA",
  //           autoCapitalize: "characters",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    id: 12,
    category_id: 1,
    short_name: "OSHEE",
    long_name: "OSHEE",
    nuis: "X1234567Y",
    address: null,
    logo: require("../assets/images/Utilities/Oshee.png"),
    searchNames: "oshee dritat fatura ",
    operatorId: "24",
    services: [
      {
        id: 1,
        name: "pay_oshee",
        desc: "pay_oshee_desc",
        type: "invoice",
        info_desc: "info_pay_oshee",
        maxContractLength: 5,
        currency: "ALL",
        fields: [
          {
            label: "oshee_customer_no",
            type: "text",
            required: true,
            autoCapitalize: "characters",
          },
        ],
      },
    ],
  },
];

export default Utilities;
