import React, { useState } from "react";
import QRCode from "qrcode.react";
import langText from "../lang/i18n";
import { GetAccountsUrl, InitInnerTransaction } from "../constants/endpoints";
import LoadingSpinner from "../components/LoadingIndicator";

import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { AccountProps, setAccounts, setChosenAcc } from "../redux/user-slice";
import ChooseAccountButton from "../components/ChooseAccountButton";
import { hexWithOpacity, logActivity } from "../hooks/functions";
import Lottie from "react-lottie";
import animationData from "../assets/782-check-mark-success.json";
import Modal from "../components/Modal";
import { getCurrentServer } from "../localDatabase/mainDB";

interface FormData {
  name: string;
  lastName: string;
  phoneNumber: string;
}

const InnerTransfer: React.FC = () => {
  const { UserToken, chosenAcc, allAccounts, PersonId } = useAppSelector(
    (store) => store.user
  );
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    lastName: "",
    phoneNumber: "",
  });
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [amount, setAmount] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");
  const [myAccountsTransfer, setMyAccountsTransfer] = useState(false);

  const [targetAccount, setTargetAccount] = useState<
    AccountProps | { id: number }
  >(allAccounts?.find((acc) => acc.id !== chosenAcc?.id)!);

  const qrCodeValue = `${formData.name} ${formData.lastName} - ${formData.phoneNumber}`;

  async function makeTransfer() {
    if (getCurrentServer() === "dev") {
      setSuccess(true);
      return;
    }
    if (Number(amount.replace(/,/g, "")) <= 0) {
      setErrorMessage(langText("amount_larger_than_0"));
      return;
    }

    const reqBody = {
      currency: chosenAcc?.currency,
      amount: amount.replace(/,/g, ""),
      message: description,
      senderAccount: chosenAcc?.id,
      recipientAccount: targetAccount.id,
    };
    logActivity(InitInnerTransaction(), reqBody);
    setLoading(true);
    const response = await fetch(InitInnerTransaction(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify(reqBody),
    });
    setLoading(false);
    logActivity("made a transaction", response.status);
    if (!response.ok) {
      setErrorMessage(langText("error"));
      return;
    }
    const responseJson = await response.json();
    logActivity(responseJson);
    setSuccess(true);
    getAllAccounts();
  }

  async function getAllAccounts() {
    const response = await fetch(GetAccountsUrl(PersonId!), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    logActivity(GetAccountsUrl(PersonId!), "GET", response.status);
    if (!response.ok) {
      setErrorMessage(langText("error"));
      setLoading(false);
      return;
    }
    const data: AccountProps[] = await response.json();
    logActivity(GetAccountsUrl(PersonId!), "GET", data);
    data.sort((a, b) =>
      a.accountType === "CHECKING_BUSINESS" ||
      a.accountType === "SAVINGS_PERSONAL"
        ? 2
        : a.currency > b.currency
        ? 1
        : -1
    );

    dispatch(setAccounts(data));
    setLoading(false);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            color: "#44494B",
          }}
        >
          Transfero brenda llogarive
        </h2>
        <br />
        <label
          htmlFor="name"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: "19px",
            lineHeight: "20px",
            color: "#44494B",
            marginBottom: 10,
          }}
        >
          Nga Llogaria:<span style={{ color: "red" }}>*</span>
        </label>
        <ChooseAccountButton
          filter={(account) => account.currency == "ALL"}
          diff={Number(amount.replace(/,/g, ""))}
          allowSavings
        />
        {/*<select*/}
        {/*  value={chosenAcc?.id}*/}
        {/*  onChange={(e) => {*/}
        {/*    dispatch(*/}
        {/*      setChosenAcc(*/}
        {/*        allAccounts?.find((acc) => acc.id === Number(e.target.value))!*/}
        {/*      )*/}
        {/*    );*/}
        {/*    if (targetAccount.id === Number(e.target.value)) {*/}
        {/*      setTargetAccount(*/}
        {/*        allAccounts?.find((acc) => acc.id !== chosenAcc?.id)!*/}
        {/*      );*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  style={{*/}
        {/*    padding: 20,*/}
        {/*    marginBottom: "17px",*/}
        {/*    border: "1px solid grey",*/}
        {/*    fontSize: 16,*/}
        {/*    borderRadius: "10px",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {!!allAccounts &&*/}
        {/*    allAccounts*/}
        {/*      ?.filter((acc) => acc.currency === "ALL")*/}
        {/*      .map((acc) => {*/}
        {/*        return (*/}
        {/*          <option value={acc.id}>*/}
        {/*            {acc.accountHolder.name + " - " + acc.id}*/}
        {/*          </option>*/}
        {/*        );*/}
        {/*      })}*/}
        {/*</select>*/}
        <br />
        <label
          htmlFor="name"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: "19px",
            lineHeight: "20px",
            color: "#44494B",
            marginBottom: 10,
          }}
        >
          Ne Llogarine:<span style={{ color: "red" }}>*</span>
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: 20,
          }}
        >
          <div
            onClick={() => setMyAccountsTransfer(true)}
            style={{
              borderRadius: 20,
              backgroundColor: hexWithOpacity(
                "#5B6DEC",
                myAccountsTransfer ? 1 : 0.5
              ),
              color: "#FFF",
              padding: "10px 20px",
            }}
          >
            Llogaritë e mia
          </div>
          <div
            onClick={() => setMyAccountsTransfer(false)}
            style={{
              borderRadius: 20,
              backgroundColor: hexWithOpacity(
                "#5B6DEC",
                !myAccountsTransfer ? 1 : 0.5
              ),
              color: "#FFF",
              padding: "10px 20px",
            }}
          >
            Llogari tjetër
          </div>
        </div>
        {myAccountsTransfer ? (
          <ChooseAccountButton
            filter={(account) =>
              account.currency == "ALL" && account.id != chosenAcc?.id
            }
            diff={-Number(amount.replace(/,/g, ""))}
            thisAccount={targetAccount as AccountProps}
            setThisAccount={setTargetAccount}
            allowSavings
          />
        ) : (
          <input
            type="number"
            style={{
              padding: "20px",
              marginBottom: "17px",
              border: "1px solid grey",
              fontSize: 20,
              borderRadius: "10px",
            }}
            onChange={(e) =>
              setTargetAccount({
                id: isNaN(Number(e.target.value))
                  ? Number(e.target.value)
                  : targetAccount.id,
              })
            }
          />
        )}
        {/*<select*/}
        {/*  value={targetAccount?.id}*/}
        {/*  onChange={(e) => {*/}
        {/*    setTargetAccount(*/}
        {/*      allAccounts?.find((acc) => acc.id === Number(e.target.value))!*/}
        {/*    );*/}
        {/*  }}*/}
        {/*  style={{*/}
        {/*    padding: 20,*/}
        {/*    marginBottom: "17px",*/}
        {/*    border: "1px solid grey",*/}
        {/*    fontSize: 16,*/}
        {/*    borderRadius: "10px",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {!!allAccounts &&*/}
        {/*    allAccounts*/}
        {/*      ?.filter(*/}
        {/*        (acc) => acc.id !== chosenAcc?.id && acc.currency === "ALL"*/}
        {/*      )*/}
        {/*      .map((acc) => {*/}
        {/*        return (*/}
        {/*          <option value={acc.id}>*/}
        {/*            {acc.accountHolder.name + " - " + acc.id}*/}
        {/*          </option>*/}
        {/*        );*/}
        {/*      })}*/}
        {/*</select>*/}
        <br />
        <br />
        <label
          htmlFor="name"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: "19px",
            lineHeight: "20px",
            color: "#44494B",
            marginBottom: 10,
          }}
        >
          Vendos shumen qe doni te dergoni
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          style={{
            padding: "20px",
            marginBottom: "17px",
            border: "1px solid grey",
            fontSize: 20,
            borderRadius: "10px",
          }}
          type="text"
          id="name"
          value={amount}
          onChange={(event) => {
            let number = event.target.value;
            if (
              isNaN(Number(number)) &&
              (amount.toString().includes(".") ? true : !number.endsWith("."))
            )
              return;
            if (number.startsWith("0") && number.length > 1)
              number = number.slice(1);
            setAmount(number);
          }}
        />
        {/*<br />*/}
        {/*<label*/}
        {/*  htmlFor="lastName"*/}
        {/*  style={{*/}
        {/*    fontFamily: "Poppins",*/}
        {/*    fontStyle: "normal",*/}
        {/*    fontWeight: 100,*/}
        {/*    fontSize: "19px",*/}
        {/*    lineHeight: "20px",*/}
        {/*    color: "#323232",*/}
        {/*    marginBottom: "1px",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Vendos Faturen:*/}
        {/*</label>*/}
        {/*<br />*/}
        {/*<input*/}
        {/*  style={{*/}
        {/*    padding: "20px",*/}
        {/*    boxSizing: "border-box",*/}
        {/*    background: "#FFFFFF",*/}
        {/*    border: "1px solid grey",*/}
        {/*    marginBottom: "20px",*/}
        {/*   */}
        {/*  }}*/}
        {/*  type="text"*/}
        {/*  id="lastName"*/}
        {/*  value={formData.lastName}*/}
        {/*  onChange={(event) => handleFormChange(event, "lastName")}*/}
        {/*/>*/}
        <br />
        <label
          htmlFor="phoneNumber"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: "19px",
            lineHeight: "20px",
            color: "#44494B",
            marginBottom: 10,
          }}
        >
          Vendos nje mesazh:
        </label>
        <input
          style={{
            padding: "20px",
            border: "1px solid grey",
            fontSize: 20,
            borderRadius: "10px",
          }}
          type="text"
          id="phoneNumber"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <br />
        <div style={{ color: "red", textAlign: "center" }}>{errorMessage}</div>
        <button
          disabled={loading || Number(amount) <= 0}
          onClick={makeTransfer}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            //   padding: '24px 108px',
            cursor: "pointer",
            gap: "10px",
            width: "372px",
            height: "68px",
            background: "#5B6DEC",
            borderRadius: "10px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "20px",
            color: "#FFFFFF",
            marginTop: "20px",
            borderColor: "transparent",
            opacity: loading || Number(amount) <= 0 ? 0.5 : 1,
          }}
        >
          {loading ? <LoadingSpinner /> : "Dërgo"}
        </button>
        <br />
      </div>
      {showQRCode && (
        <div
          style={{
            padding: "40px",
            gap: "10px",
            marginLeft: "-19000px",
            marginRight: "550px",
            marginTop: "140px",
            backgroundColor: "white",
            borderRadius: "25px",
            border: "1px solid #546BEA",
          }}
        >
          <QRCode
            value={qrCodeValue}
            size={367}
            bgColor="#FFFFFF"
            fgColor="#546BEA"
            level="H"
            // includeMargin={false}
            renderAs="svg"
          />
        </div>
      )}
      <Modal
        isVisible={success}
        setIsVisible={(bool) => {
          setSuccess(bool);
          setAmount("");
          setDescription("");
        }}
        title={"Transferta u krye me sukses"}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={400}
              width={400}
            />

            <button
              style={{
                marginTop: 10,
                width: "100%",
                padding: "10px",
                backgroundColor: "#546BEA",
                color: "white",
                borderRadius: "10px",
                fontSize: 18,
                border: "none",
                fontFamily: "Poppins",
              }}
              onClick={() => {
                setSuccess(false);
                setAmount("");
                setDescription("");
              }}
              disabled={loading}
            >
              {langText("continue")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InnerTransfer;
